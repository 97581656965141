<template>
  <div v-if="hasNote">
    <div class="btn btn-secondary  d-block" style="font-size: 0.9rem;background:#ba2526" data-bs-toggle="modal"
         data-bs-target="#exampleModal">
      <font-awesome-icon :icon="['fal', 'file-edit']"/>
      {{ wordings[currentLanguage].EDIT_NOTE }}
    </div>
  </div>
  <div v-if="!hasNote">
    <div class="btn btn-secondary  d-block" style="font-size: 0.9rem;background:#ba2526" data-bs-toggle="modal"
         data-bs-target="#exampleModal">
      <font-awesome-icon :icon="['fal', 'file-edit']"/>
      {{ wordings[currentLanguage].ADD_NOTE }}
    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       data-bs-backdrop="false">
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content note-modal">
        <div class="modal-header" style="background: lightgrey">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ hasNote ? wordings[currentLanguage].EDIT_NOTE : wordings[currentLanguage].ADD_NOTE }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <textarea type="text" rows="3" placeholder="..." v-model="currentNote" style="width:100%; border-radius:0"/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
              wordings[currentLanguage].CLOSE
            }}
          </button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteNote()" v-if="hasNote">
            {{ wordings[currentLanguage].DELETE_NOTE }}
          </button>
          <button type="button" class="btn btn-primary" @click="saveNote()" data-bs-dismiss="modal">
            {{ wordings[currentLanguage].SAVE_NOTE }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {addIcons} from "ionicons";
import {heart, heartOutline} from "ionicons/icons";
import {mapActions, mapState} from "vuex";
import moment from "moment";

addIcons({"heart-outline": heartOutline, heart});

export default defineComponent({
  data() {
    return {
      hasNote: false,
      activeId: null,
      currentNote: null,

    };
  },
  components: {},
  props: ["favId", "parentId"],
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion'])
  },
  methods: {
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'saveItem', 'togglePresentation', 'toggleSession', 'saveRemoteNote','deleteRemoteNote']),


    async deleteNote() {
      this.currentNote = null;
      this.saveNote()
      await this.deleteRemoteNote(this.activeId);

    },
    async saveNote() {
      let notes = await this.getItem('notes_' + this.currentConference +'_v2');
      //console.log('NOTES', this.currentNote)
      notes = notes || [];
      let noteIndex = notes.findIndex(n => n.id == this.activeId);
      //console.log('notes exist', noteIndex)
      if (this.currentNote && this.currentNote.length > 0) {
        if (notes[noteIndex]) {
          notes[noteIndex].note = this.currentNote;
          this.hasNote = true;
          this.saveNoteList(notes);
        } else {
          notes.push({id: this.activeId, note: this.currentNote})
          this.hasNote = true;
          this.saveNoteList(notes);
        }
        await this.saveRemoteNote({id: this.activeId, text: this.currentNote});

      } else {
        if (notes[noteIndex]) {
          notes.splice(noteIndex, 1)
          this.hasNote = false;
          this.saveNoteList(notes);
        }
      }


    },

    async checkNote(id) {
      let notes = await this.getItem('notes_' + this.currentConference +'_v2');
      notes = notes || [];
      let noteIndex = notes.findIndex(n => n.id == id);
      if (noteIndex != -1) {
        //console.log('NOTES', id)
        this.activeId = id;
        this.currentNote = notes[noteIndex].note
        this.hasNote = true;
      }
    },
    async saveNoteList(notes) {
      //console.log('saveNotes', notes)
      this.saveItem({name: 'notes_' + this.currentConference +'_v2', item: notes})
    },
  },

  created() {
    this.activeId = this.favId
    this.checkNote(this.favId)
  },

  watch: {
    'favId': async function (newVal) {
      this.activeId = newVal
    },
  },
})
;
</script>
<style lang="scss">
.loader {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ion-spinner {
    margin-right: 10px;
  }
}

.note-modal {
  -moz-box-shadow: -4px 4px 5px #b6b6b6;
  -webkit-box-shadow: -4px 4px 5px #b6b6b6;
  box-shadow: -4px 4px 5px #b6b6b6;
}


.note-button {
  width: 100%;
  background: rgb(239, 239, 239);
  text-align: center;
  border-radius: 3px;
  background: #ccd3d3;
  color: #7b7979
}
</style>
